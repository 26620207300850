$primary: #1266F1;
$secondary: #B23CFD;
$success: #00B74A;
$danger: #F93154;
$warning: #FFA900;
$info: #39C0ED;

$sidenav-footer: #1270a4;
$sidenav-footer-color: #fff;

$nav-link-color: rgba(255, 255, 255, 0.5);
$nav-link-hover-color:  #fff;

$link-decoration: none;

// Pagination
$pagination-disabled-color: #dee2e6;

$pagination-color: #626262;
$pagination-bg: transparent;
$pagination-border-width: 0;

$pagination-hover-bg: transparent;

$pagination-focus-bg: transparent;
$pagination-focus-box-shadow: 0;

$pagination-active-bg: transparent;
$pagination-active-color: $primary;

