.section-home {
	.main-title {
		font-size: 6rem;
		font-weight: 700;
		margin: 76px 106px 0 0;
	}

	.shape-1 {
		width: 236px;
		height: 236px;
		margin: 51px 51px 0 auto;

		.svg-link {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			fill: currentColor;
		}

		.svg-content {
			fill: currentColor;
		}


	}
}
