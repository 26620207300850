.html-bg {
	background-image: url("#{$image-path}/football.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
	opacity: 0.07;
	position: absolute;
	z-index: -1;
}
